import { Box } from '@mui/material';
import type { Bounds } from 'core';
import type { Property } from 'csstype';
import 'mapbox-gl/dist/mapbox-gl.css';
import type { FC, PropsWithChildren } from 'react';
import {
  FullscreenControl,
  Map as Mapbox,
  NavigationControl,
} from 'react-map-gl';
import { Spinner } from '../spinner/spinner.js';

export const MAP_PADDING = 16;

interface Props {
  bearing: number;
  bounds?: Bounds;
  height?:
    | Property.Height<string | number>
    | NonNullable<Property.Height<string | number> | undefined>[]
    | undefined;
  mapStyle: string;
  maxHeight?:
    | Property.Height<string | number>
    | NonNullable<Property.Height<string | number> | undefined>[]
    | undefined;
  maxWidth?:
    | Property.Width<string | number>
    | NonNullable<Property.Width<string | number> | undefined>[]
    | undefined;
  minHeight?:
    | Property.Height<string | number>
    | NonNullable<Property.Height<string | number> | undefined>[]
    | undefined;
  minWidth?:
    | Property.Width<string | number>
    | NonNullable<Property.Width<string | number> | undefined>[]
    | undefined;
  width?:
    | Property.Width<string | number>
    | NonNullable<Property.Width<string | number> | undefined>[]
    | undefined;
}

export const MapView: FC<PropsWithChildren<Props>> = ({ bounds, ...props }) =>
  bounds ? (
    <MapWithBounds bounds={bounds} {...props} />
  ) : (
    <Box
      height={props.height ?? '100%'}
      width={props.height ?? '100%'}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner />
    </Box>
  );

export const MapWithBounds: FC<PropsWithChildren<Props & { bounds: Bounds }>> =
  ({
    bearing,
    bounds,
    children,
    height = '100%',
    mapStyle,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    width = '100%',
  }) => (
    <Box
      height={height}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      minHeight={minHeight}
      minWidth={minWidth}
      position="relative"
      width={width}
      sx={{
        '& .mapboxgl-ctrl-attrib': {
          opacity: 0.8,
          fontSize: '0.5rem',
        },
        '& .mapboxgl-ctrl-logo': {
          opacity: 0.6,
        },
      }}
    >
      <Mapbox
        initialViewState={{
          bearing,
          bounds,
          fitBoundsOptions: {
            padding: {
              left: MAP_PADDING,
              right: MAP_PADDING,
              top: MAP_PADDING,
              bottom: MAP_PADDING,
            },
          },
        }}
        cooperativeGestures={true}
        mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN as string}
        mapStyle={mapStyle}
        minZoom={11.5}
      >
        <FullscreenControl />
        <NavigationControl showCompass={false} />
        {children}
      </Mapbox>
    </Box>
  );
