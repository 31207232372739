import type { Bounds } from 'core';
import type { Property } from 'csstype';
import type { FC, PropsWithChildren } from 'react';
import { useEffect } from 'react';
import { useMap } from 'react-map-gl';
import { withMap } from '../../hoc/with-map.js';
import { magneticDeclination } from '../../utils/geo.js';
import { MAP_PADDING, MapView } from './view.js';

interface Props {
  bounds?: Bounds;
  height?:
    | Property.Height<string | number>
    | NonNullable<Property.Height<string | number> | undefined>[]
    | undefined;
  mapStyle: string;
  minHeight?:
    | Property.Height<string | number>
    | NonNullable<Property.Height<string | number> | undefined>[]
    | undefined;
  minWidth?:
    | Property.Width<string | number>
    | NonNullable<Property.Width<string | number> | undefined>[]
    | undefined;
  width?:
    | Property.Width<string | number>
    | NonNullable<Property.Width<string | number> | undefined>[]
    | undefined;
}

const controller =
  (View: typeof MapView): FC<PropsWithChildren<Props>> =>
  (props) => {
    const { default: map } = useMap();

    useEffect(() => {
      if (!map) return;

      const resize = (): void => {
        map.resize();
      };

      map.on('idle', resize);

      return () => {
        map.off('idle', resize);
      };
    }, [map]);

    useEffect(() => {
      if (!map || !props.bounds) return;

      map.fitBounds(props.bounds, {
        bearing: magneticDeclination(props.bounds[0], props.bounds[1]),
        padding: {
          left: MAP_PADDING,
          right: MAP_PADDING,
          top: MAP_PADDING,
          bottom: MAP_PADDING,
        },
      });
    }, [map, props.bounds, props.bounds?.[0], props.bounds?.[1]]);

    return View({
      ...props,
      bearing: props.bounds
        ? magneticDeclination(props.bounds[0], props.bounds[1])
        : 0,
    });
  };

export default withMap(controller(MapView));
